.container,
.container-sm,
.container-xs {
  width: 100%;
  margin: 0 auto;
  &-no-margin {
    margin: auto 0;
  }
  padding-left: $container--padding__mobile;
  padding-right: $container--padding__mobile;

  @include media(">small") {
    padding-left: $container--padding__desktop;
    padding-right: $container--padding__desktop;
  }
}

.container {
  max-width: $container--width + ($container--padding__desktop * 2);
}

.container-sm {
  // max-width: $container--width-sm + ( $container--padding__desktop * 2 );
  // background-image: url(../../../images/intro-bg.jpg);
  // background-image: linear-gradient(to bottom right, black, #365679);

  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial {
  // background-color: #f4f6fa;
}

.holder-sm {
  // max-width: $container--width-sm + ( $container--padding__desktop * 2 );
  // background-image: url(../../../images/background.gif);

  height: 100%;
  margin-left: 5%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include desktop {
   max-width: 1440px;
    width: 1440px;
  }
  @include mobile {
    margin: auto;
  }
  @include tablet {
    margin: 0;
  }
}

.about-sm {
  // max-width: $container--width-sm + ( $container--padding__desktop * 2 );
  // background-image: url(../../../images/background-02.gif);
  height: 10%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: repeat;
  @include tablet-and-mobile {
    margin: auto;
  }
}

.contact-sm {
  // max-width: $container--width-sm + ( $container--padding__desktop * 2 );
  // background-image: url(../../../images/background-02.gif);
  height: 10%;
  margin-left: 10%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: repeat;
  @include tablet-and-mobile {
    margin: auto;
  }
}

.roadmap-sm {
  // max-width: $container--width-sm + ( $container--padding__desktop * 2 );
  background-image: url(../../../images/background-03.gif);
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: repeat;
}

.roadmap-details-sm {
  // max-width: $container--width-sm + ( $container--padding__desktop * 2 );
  background-image: url(../../../images/background-04.gif);
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
}

.container-xs {
  max-width: $container--width-xs + ($container--padding__desktop * 2);
  &-none {
    @include tablet-and-mobile {
      display: none;
    }
  }
}

[class*="container"] {
  [class*="container"] {
    padding-left: 0;
    padding-right: 0;
  }

  .container-sm {
    max-width: $container--width-sm;
  }

  .container-xs {
    max-width: $container--width-xs;
  }
}

.btn-shine {
    // position: 'absolute';
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%,-50%);
    // padding: 12px 48px;
    color: #ffffff;
    background: linear-gradient(to right, rgb(118, 168, 88) 10%, rgb(72, 158, 76) 10%, #f7f700 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 4s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    font-weight: 600;
    text-decoration: none;
    // white-space: nowrap;
  };
      
  @keyframes shine {
    0% {
      background-position: 400px
    }
    60% {
      background-position: 800px
    }
      
    100% {
      background-position: 400px
    }
    
  }

  .btn-shine2 {
    // position: 'absolute';
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%,-50%);
    // padding: 12px 48px;
    color: #ffffff;
    background: linear-gradient(to right, rgb(118, 168, 88) 10%, rgb(72, 158, 76) 10%, #f7f700 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 4s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    font-weight: 600;
    text-decoration: none;
    // white-space: nowrap;
  };
      
  @keyframes shine {
    0% {
      background-position: 400px
    }
    60% {
      background-position: 1050px
    }
      
    100% {
      background-position: 400px
    }
    
  }
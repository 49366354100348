.features-tiles-item-image {
	display: inline-flex;
	border-radius: 50%;
	background-color: get-color(primary, 5);
}

.features-tiles-item-image-black {
	display: inline-flex;
	border-radius: 50%;
	background-color: get-color(dark, 4);
}

.split-item-about {
  border-radius: 32px;
  background-color: white;
  padding: 64px;
}

.about--content-small {
  font-size: 18px;
  line-height: 32px;
}
.split-item-contact {
  border-radius: 32px;
  background-color: white;
  padding: 64px;
}

.contact--content-small {
  font-size: 18px;
  line-height: 32px;
}

.cta {
  background-color: get-color(light, 4);
  &-slogan {
    background-color: get-color(light, 5);
    margin-bottom: 0px;
  }

  .form-input {
    border-color: transparent;
    border-radius: 2px;
  }
}

.cta-inner {
  padding-left: 16px;
  padding-right: 16px;
  // background-color: get-color(dark, 4);
  // background-image: url(../../../images/cta-illustration.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
}

.slogan {
  &--content {
    width: 758.644px;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    color: get-color(dark, 1);
    @include tablet-and-mobile {
      width: auto;
    }
  }
}

@include media(">medium") {
  .cta {
    .form-input {
      min-width: 280px;
    }
  }

  .cta-inner {
    padding-left: 48px;
    padding-right: 48px;
  }
}

@include media(">medium") {
  .slogan {
    &--content {
      min-width: 280px;
      padding-left: 48px;
      padding-right: 48px;
    }
  }

  .cta-inner {
  }
}
